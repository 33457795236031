


const sprachen =  [
    {
        language: 'Premium/Multilingual',

        elevenvoices: [





        ],
    },
    {
        language: 'Swisscom-German',
        awsvoices: [
            ["Vicki,neural,aws", "Vicki [neural voice][German]"],
        ],
    },
    {
        language: 'Swisscom-French',
        awsvoices: [
            ["Lea,neural,aws", "Léa [neural voice][French]"],
        ],
    },
    {
        language: 'Swisscom-Italian',
        awsvoices: [
            ["Bianca,neural,aws", "Bianca [neural voice][Italian]"],
        ],
    },
    {
        language: 'Swisscom-GB-English',
        awsvoices: [
            ["Amy,neural,aws", "Amy [Neural Voice][English (British)]"],
        ],
    },
    {

        language: 'German',
        awsvoices: [
            ["Vicki,standard,aws", "Vicki [German]"],
            ["Vicki,neural,aws", "Vicki [neural voice][German]"],
            ["Marlene,standard,aws", "Marlene [German]"],
            ["Hans,standard,aws", "Hans [German]"],
            ["Daniel,neural,aws", "Daniel [neural voice][German]"],




        ],
        azurevoices: [
            ["de-DE-KlarissaNeural,standard,azure", "Klarissa [Deutsch]"],
            ["de-DE-KlausNeural,standard,azure", "Klaus [Deutsch]"],
            ["de-DE-RalfNeural,standard,azure", "Ralf [Deutsch]"],
            ["de-DE-AmalaNeural,standard,azure", "Amala [Deutsch]"],
            ["de-DE-LouisaNeural,standard,azure", "Louisa [Deutsch]"],
            ["de-DE-BerndNeural,standard,azure", "Bernd [Deutsch]"],
            ["de-DE-ChristophNeural,standard,azure", "Christoph [Deutsch]"],
            ["de-DE-ConradNeural,standard,azure", "Conrad [Deutsch]"],
            ["de-DE-GiselaNeural,standard,azure", "Gisela (girl) [Deutsch]"],
            ["de-DE-KasperNeural,standard,azure", "Kasper [Deutsch]"],
            ["de-DE-KatjaNeural,standard,azure", "Katja [Deutsch]"],
            ["de-DE-KillianNeural,standard,azure", "Killian [Deutsch]"],

        ],
        googlevoices: [
            ["de-DE-Standard-A, standard,googlespeech", "de-DE-Standard-A FEMALE [Germany]"],
            ["de-DE-Standard-B, standard,googlespeech", "de-DE-Standard-B MALE [Germany]"],
            ["de-DE-Standard-C, standard,googlespeech", "de-DE-Standard-C FEMALE [Germany]"],
            ["de-DE-Standard-D, standard,googlespeech", "de-DE-Standard-D MALE [Germany]"],
            ["de-DE-Standard-E, standard,googlespeech", "de-DE-Standard-E MALE [Germany]"],
            ["de-DE-Standard-F, standard,googlespeech", "de-DE-Standard-F FEMALE [Germany]"],
            ["de-DE-Wavenet-A, standard,googlespeech", "de-DE-Wavenet-A FEMALE [Germany]"],
            ["de-DE-Wavenet-B, standard,googlespeech", "de-DE-Wavenet-B MALE [Germany]"],
            ["de-DE-Wavenet-C, standard,googlespeech", "de-DE-Wavenet-C FEMALE [Germany]"],
            ["de-DE-Wavenet-D, standard,googlespeech", "de-DE-Wavenet-D MALE [Germany]"],
            ["de-DE-Wavenet-E, standard,googlespeech", "de-DE-Wavenet-E MALE [Germany]"],
            ["de-DE-Wavenet-F, standard,googlespeech", "de-DE-Wavenet-F FEMALE [Germany]"],


        ],
        elevenvoices: [




        ],

    },
    {
        language: 'Albanian',
        awsvoices: [


        ],
        azurevoices: [
            ["sq-AL-AnilaNeural,standard,azure", "Anila [Albanian]"],
            ["sq-AL-IlirNeural,standard,azure", "Ilir [Albanian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Arabic',
        awsvoices: [
            ["Zeina,standard,aws", "Zeina [Arabic]"],
        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Arabic (Gulf)',
        awsvoices: [
            ["Hala,neural,aws", "Hala [Arabic (Gulf)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Austria',
        awsvoices: [


        ],
        azurevoices: [
            ["de-AT-IngridNeural,standard,azure", "Ingrid [Österreich]"],
            ["de-AT-JonasNeural,standard,azure", "Jonas [Österreich]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Bosnian',
        awsvoices: [


        ],
        azurevoices: [
            ["bs-BA-GoranNeural,standard,azure", "Goran [Bosnian]"],
            ["bs-BA-VesnaNeural,standard,azure", "Vesna [Bosnian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Bulgarian',
        awsvoices: [


        ],
        azurevoices: [
            ["bg-BG-BorislavNeural,standard,azure", "Borislav [Bulgarian]"],
            ["bg-BG-KalinaNeural,standard,azure", "Kalina [Bulgarian]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Catalan',
        awsvoices: [
            ["Arlet,neural,aws", "Arlet [Catalan]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Chinese (Cantonese)',
        awsvoices: [
            ["Hiujin,neural,aws", "Hiujin [Chinese (Cantonese)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Chinese, Mandarin',
        awsvoices: [
            ["Zhiyu,neural,aws", "Zhiyu [Neural Voice][Chinese (Mandarin)]"],
            ["Zhiyu,standard,aws", "Zhiyu [Chinese, Mandarin]"],


        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Croatia',
        awsvoices: [


        ],
        azurevoices: [
            ["hr-HR-GabrijelaNeural,standard,azure", "Gabrijela [Croatia]"],
            ["hr-HR-SreckoNeural,standard,azure", "Srecko [Croatia]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Czech',
        awsvoices: [


        ],
        azurevoices: [
            ["cs-CZ-AntoninNeural,standard,azure", "Antonin [Czech]"],
            ["cs-CZ-VlastaNeural,standard,azure", "Vlasta [Czech]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Danish',
        awsvoices: [
            ["Naja,standard,aws", "Naja[Danish]"],
            ["Mads,standard,aws", "Mads [Danish]"],
            ["Sofie,neural,aws", "Sofie [Neural Voice][Danish]"],

        ],
        azurevoices: [
            ["da-DK-ChristelNeural,standard,azure", "Christel [Danish]"],
            ["da-DK-JeppeNeural,standard,azure", "Jeppe [Danish]"],

        ],
        googlevoices: [
            ["da-DK-Standard-A, standard,googlespeech", "da-DK-Standard-A FEMALE [Danish]"],
            ["da-DK-Standard-C, standard,googlespeech", "da-DK-Standard-C MALE [Danish]"],
            ["da-DK-Standard-D, standard,googlespeech", "da-DK-Standard-D FEMALE [Danish]"],
            ["da-DK-Standard-E, standard,googlespeech", "da-DK-Standard-E FEMALE [Danish]"],
            ["da-DK-Wavenet-A, standard,googlespeech", "da-DK-Wavenet-A FEMALE [Danish]"],
            ["da-DK-Wavenet-C, standard,googlespeech", "da-DK-Wavenet-C MALE [Danish]"],
            ["da-DK-Wavenet-D, standard,googlespeech", "da-DK-Wavenet-D FEMALE [Danish]"],
            ["da-DK-Wavenet-E, standard,googlespeech", "da-DK-Wavenet-E FEMALE [Danish]"],
        ],
    },
    {
        language: 'Dutch',
        awsvoices: [
            ["Laura,neural,aws", "Laura [Neural Voice][Dutch]"],
            ["Lotte,standard,aws", "Lotte [Dutch]"],
            ["Ruben,standard,aws", "Ruben [Dutch]"],

        ],
        azurevoices: [
            ["nl-NL-ColetteNeural,standard,azure", "Colette [Dutch]"],
            ["nl-NL-FennaNeural,standard,azure", "Fenna [Dutch]"],
            ["nl-NL-MaartenNeural ,standard,azure", "Maarten [Dutch]"],

        ],
        googlevoices: [
            ["nl-NL-Standard-A, standard,googlespeech", "nl-NL-Standard-A FEMALE [Dutch]"],
            ["nl-NL-Standard-B, standard,googlespeech", "nl-NL-Standard-B MALE [Dutch]"],
            ["nl-NL-Standard-C, standard,googlespeech", "nl-NL-Standard-C MALE [Dutch]"],
            ["nl-NL-Standard-D, standard,googlespeech", "nl-NL-Standard-D FEMALE [Dutch]"],
            ["nl-NL-Standard-E, standard,googlespeech", "nl-NL-Standard-E FEMALE [Dutch]"],
            ["nl-NL-Wavenet-A, standard,googlespeech", "nl-NL-Wavenet-A FEMALE [Dutch]"],
            ["nl-NL-Wavenet-B, standard,googlespeech", "nl-NL-Wavenet-B MALE [Dutch]"],
            ["nl-NL-Wavenet-C, standard,googlespeech", "nl-NL-Wavenet-C MALE [Dutch]"],
            ["nl-NL-Wavenet-D, standard,googlespeech", "nl-NL-Wavenet-D FEMALE [Dutch]"],
            ["nl-NL-Wavenet-E, standard,googlespeech", "nl-NL-Wavenet-E FEMALE [Dutch]"],
        ],
    },
    {
        language: 'Dutch (Belgium)',
        awsvoices: [

        ],
        azurevoices: [
            ["nl-BE-ArnaudNeural,standard,azure", "Arnaud [Flemish]"],
            ["nl-BE-DenaNeural,standard,azure", "Dena [Flemish]"],

        ],
        googlevoices: [
            ["nl-BE-Standard-A, standard,googlespeech", "nl-BE-Standard-A FEMALE [Flemish]"],
            ["nl-BE-Standard-B, standard,googlespeech", "nl-BE-Standard-B MALE [Flemish]"],
            ["nl-BE-Wavenet-A, standard,googlespeech", "nl-BE-Wavenet-A FEMALE [Flemish]"],
            ["nl-BE-Wavenet-B, standard,googlespeech", "nl-BE-Wavenet-B MALE [Flemish]"],
        ],
    },
    {
        language: 'English (Australia)',
        awsvoices: [
            ["Nicole,standard,aws", "Nicole [English (Australian)]"],
            ["Olivia,neural,aws", "Olivia [Neural Voice][English (Australian)]"],
            ["Russell,standard,aws", "Russell [English (Australian)]"],
        ],
        azurevoices: [
            ["en-AU-AnnetteNeural,standard,azure", "Annette [English (Australia)]"],
            ["en-AU-CarlyNeural,standard,azure", "Carly [English (Australia)]"],
            ["en-AU-DarrenNeural,standard,azure", "Darren [English (Australia)]"],
            ["en-AU-DuncanNeural ,standard,azure", "Duncan [English (Australia)]"],
            ["en-AU-ElsieNeural,standard,azure", "Elsie [English (Australia)]"],
            ["en-AU-FreyaNeural,standard,azure", "Freya [English (Australia)]"],
            ["en-AU-JoanneNeural,standard,azure", "Joanne [English (Australia)]"],
            ["en-AU-KenNeural,standard,azure", "Ken [English (Australia)]"],
            ["en-AU-KimNeural,standard,azure", "Kim [English (Australia)]"],
            ["en-AU-NatashaNeural,standard,azure", "Natasha [English (Australia)]"],
            ["en-AU-NeilNeural,standard,azure", "Neil [English (Australia)]"],
            ["en-AU-TimNeural,standard,azure", "Tim [English (Australia)]"],
            ["en-AU-TinaNeural,standard,azure", "Tina [English (Australia)]"],
            ["en-AU-WilliamNeural,standard,azure", "William [English (Australia)]"],

        ],
        googlevoices: [
            ["en-AU-Standard-A, standard,googlespeech", "en-AU-Standard-A FEMALE [English (Australia)]"],
            ["en-AU-Standard-B, standard,googlespeech", "en-AU-Standard-B MALE [English (Australia)]"],
            ["en-AU-Standard-C, standard,googlespeech", "en-AU-Standard-C FEMALE [English (Australia)]"],
            ["en-AU-Standard-D, standard,googlespeech", "en-AU-Standard-D MALE [English (Australia)]"],
            ["en-AU-Wavenet-A, standard,googlespeech", "en-AU-Wavenet-A FEMALE [English (Australia)]"],
            ["en-AU-Wavenet-B, standard,googlespeech", "en-AU-Wavenet-B MALE [English (Australia)]"],
            ["en-AU-Wavenet-C, standard,googlespeech", "en-AU-Wavenet-C FEMALE [English (Australia)]"],
            ["en-AU-Wavenet-D, standard,googlespeech", "en-AU-Wavenet-D MALE [English (Australia)]"],
        ],
    },
    {
        language: 'English (Great Britain)',
        awsvoices: [
            ["Amy,neural,aws", "Amy [Neural Voice][English (British)]"],
            ["Emma,neural,aws", "Emma [Neural Voice][English (British)]"],
            ["Brian,neural,aws", "Brian [Neural Voice][English (British)]"],
            ["Arthur,standard,aws", "Arthur [English (British)]"],
        ],
        azurevoices: [
            ["en-GB-LibbyNeural,standard,azure", "Libby [Great Britain]"],
            ["en-GB-MiaNeural,standard,azure", "Mia [Great Britain]"],
            ["en-GB-RyanNeural,standard,azure", "Ryan [Great Britain]"],
            ["en-GB-SoniaNeural,standard,azure", "Sonia [Great Britain]"],

        ],
        googlevoices: [
            ["en-GB-Standard-A, standard,googlespeech", "en-GB-Standard-A FEMALE [Great Britain]"],
            ["en-GB-Standard-B, standard,googlespeech", "en-GB-Standard-B MALE [Great Britain]"],
            ["en-GB-Standard-C, standard,googlespeech", "en-GB-Standard-C FEMALE [Great Britain]"],
            ["en-GB-Standard-D, standard,googlespeech", "en-GB-Standard-D MALE [Great Britain]"],
            ["en-GB-Standard-F, standard,googlespeech", "en-GB-Standard-F FEMALE [Great Britain]"],
            ["en-GB-Wavenet-A, standard,googlespeech", "en-GB-Wavenet-A FEMALE [Great Britain]"],
            ["en-GB-Wavenet-B, standard,googlespeech", "en-GB-Wavenet-B MALE [Great Britain]"],
            ["en-GB-Wavenet-C, standard,googlespeech", "en-GB-Wavenet-C FEMALE [Great Britain]"],
            ["en-GB-Wavenet-D, standard,googlespeech", "en-GB-Wavenet-D MALE [Great Britain]"],
            ["en-GB-Wavenet-F, standard,googlespeech", "en-GB-Wavenet-F FEMALE [Great Britain]"],
        ],

    },
    {
        language: 'English (Indian)',
        awsvoices: [
            ["Aditi,standard,aws", "Aditi [English (Indian)]"],
            ["Raveena,standard,aws", "Raveena [English (Indian)]"],
            ["Kajal,neural,aws", "Kajal [Neural Voice][English (Indian)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'English (Ireland)',
        awsvoices: [

            ["Niamh,neural,aws", "Niamh [Neural Voice][English(Ireland)]"],

        ],
        azurevoices: [
            ["en-IE-ConnorNeural,standard,azure", "Connor [English(Ireland)]"],
            ["en-IE-EmilyNeural,standard,azure", "Emily [English(Ireland)]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'English (New Zealand)',
        awsvoices: [

            ["Aria,neural,aws", "Aria [Neural Voice][English (New Zealand)]"],

        ],
        azurevoices: [
            ["en-NZ-MitchellNeural,standard,azure", "Mitchell [English (New Zealand)]"],
            ["en-NZ-MollyNeural,standard,azure", "Molly [English (New Zealand)]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'English (South African)',
        awsvoices: [
            ["Ayanda,neural,aws", "Ayanda [Neural Voice][English (South African)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'English (Welsh)',
        awsvoices: [
            ["Geraint,standard,aws", "Geraint [English (Welsh)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Estonian',
        awsvoices: [


        ],
        azurevoices: [
            ["et-EE-AnuNeural,standard,azure", "Anu [Estonian]"],
            ["et-EE-KertNeural,standard,azure", "Kert [Estonian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Finland',
        awsvoices: [
            ["Suvi,neural,aws", "Suvi [Neural Voice][Finland]"],

        ],
        azurevoices: [
            ["fi-FI-HarriNeural,standard,azure", "Harri [Finland]"],
            ["fi-FI-NooraNeural,standard,azure", "Noora [Finland]"],
            ["fi-FI-SelmaNeural,standard,azure", "Selma [Finland]"],

        ],
        googlevoices: [
            ["fi-FI-Standard-A, standard,googlespeech", "fi-FI-Standard-A FEMALE [Finland]"],
            ["fi-FI-Wavenet-A, standard,googlespeech", "fi-FI-Wavenet-A FEMALE [Finland]"],
        ],
    },
    {
        language: 'French',
        awsvoices: [
            ["Celine,standard,aws", "Céline [French]"],
            ["Lea,standard,aws", "Léa [French]"],
            ["Lea,neural,aws", "Léa [neural voice][French]"],
            ["Mathieu,standard,aws", "Mathieu [French]"],


        ],
        azurevoices: [
            ["fr-FR-DeniseNeural,standard,azure", "Denise [French]"],
            ["fr-FR-HenriNeural,standard,azure", "Henri [French]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'French (Canadian)',
        awsvoices: [
            ["Chantal,standard,aws", "Chantal [French (Canadian)]"],
            ["Gabrielle,neural,aws", "Gabrielle [Neural Voice][French (Canadian)]"],
            ["Liam,neural,aws", "Liam [Neural Voice][French (Canadian)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Georgian',
        awsvoices: [

        ],
        azurevoices: [

            ["ka-GE-EkaNeural,standard,azure", "Eka (female)[Georgian]"],
            ["ka-GE-GiorgiNeural,standard,azure", "Giorgi (male)[Georgian]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Greek',
        awsvoices: [

        ],
        azurevoices: [
            ["el-GR-AthinaNeural,standard,azure", "Athina [Greek]"],
            ["el-GR-NestorasNeural,standard,azure", "Nestoras [Greek]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Hindi',
        awsvoices: [
            ["Aditi,standard,aws", "Aditi [Hindi]"],
            ["Kajal,neural,aws", "Kajal [Neural Voice][Hindi]"],
        ],
        azurevoices: [

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Hungarian',
        awsvoices: [


        ],
        azurevoices: [
            ["hu-HU-NoemiNeural,standard,azure", "Noemi [Hungarian]"],
            ["hu-HU-TamasNeural,standard,azure", "Tamas [Hungarian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Icelandic',
        awsvoices: [
            ["Dóra,standard,aws", "Dóra [Icelandic]"],
            ["Karl,standard,aws", "Karl [Icelandic]"],


        ],
        azurevoices: [
            ["is-IS-GudrunNeural,standard,azure", "Gudrun [Icelandic]"],
            ["is-IS-GunnarNeural,standard,azure", "Gunnar [Icelandic]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Irish',
        awsvoices: [



        ],
        azurevoices: [
            ["ga-IE-ColmNeural,standard,azure", "Colm [Irish]"],
            ["ga-IE-OrlaNeural,standard,azure", "Orla [Irish]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Italian',
        awsvoices: [
            ["Carla,standard,aws", "Carla [Italian]"],
            ["Bianca,standard,aws", "Bianca [Italian]"],
            ["Bianca,neural,aws", "Bianca [neural voice][Italian]"],
            ["Giorgio,standard,aws", "Giorgio [Italian]"],

        ],
        azurevoices: [
            ["it-IT-DiegoNeural,standard,azure", "Diego [Italian]"],
            ["it-IT-ElsaNeural,standard,azure", "Elsa [Italian]"],
            ["it-IT-IsabellaNeural,standard,azure", "Isabella [Italian]"],
            ["it-IT-BenignoNeural,standard,azure", "Benigno [Italian]"],
            ["it-IT-CalimeroNeural,standard,azure", "Calimero [Italian]"],
            ["it-IT-CataldoNeural ,standard,azure", "Cataldo [Italian]"],
            ["it-IT-FabiolaNeural,standard,azure", "Fabiola [Italian]"],
            ["it-IT-FiammaNeural,standard,azure", "Fiamma [Italian]"],
            ["it-IT-GianniNeurall,standard,azure", "Gianni [Italian]"],
            ["it-IT-ImeldaNeural,standard,azure", "Imelda [Italian]"],
            ["it-IT-IrmaNeural,standard,azure", "Irma [Italian]"],
            ["it-IT-LisandroNeural,standard,azure", "Lisandro [Italian]"],
            ["it-IT-PalmiraNeural ,standard,azure", "Palmira [Italian]"],
            ["it-IT-PierinaNeural,standard,azure", "Pierina [Italian]"],
            ["it-IT-RinaldoNeural,standard,azure", "Rinaldo [Italian]"],


        ],
        googlevoices: [
            ["it-IT-Standard-A, standard,googlespeech", "it-IT-Standard-A FEMALE [Italian]"],
            ["it-IT-Standard-B, standard,googlespeech", "it-IT-Standard-B FEMALE [Italian]"],
            ["it-IT-Standard-C, standard,googlespeech", "it-IT-Standard-C MALE [Italian]"],
            ["it-IT-Standard-D, standard,googlespeech", "it-IT-Standard-D MALE [Italian]"],
            ["it-IT-Wavenet-A, standard,googlespeech", "it-IT-Wavenet-A FEMALE [Italian]"],
            ["it-IT-Wavenet-B, standard,googlespeech", "it-IT-Wavenet-B FEMALE [Italian]"],
            ["it-IT-Wavenet-C, standard,googlespeech", "it-IT-Wavenet-C MALE [Italian]"],
            ["it-IT-Wavenet-D, standard,googlespeech", "it-IT-Wavenet-D MALE [Italian]"],
        ],
    },
    {
        language: 'Japanese',
        awsvoices: [
            ["Mizuki,standard,aws", "Mizuki [Japanese]"],
            ["Takumi,standard,aws", "Takumi [Japanese]"],
            ["Kazuha,neural,aws", "Kazuha [Neural Voice][Japanese]"],
            ["Tomoko,neural,aws", "Tomoko [Neural Voice][Japanese]"],
        ],
        azurevoices: [

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Korean',
        awsvoices: [
            ["Seoyeon,neural,aws", "Seoyeon [Neural Voice][Korean]"],
            ["Seoyeon,standard,aws", "Seoyeon [Korean]"],


        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Latvian',
        awsvoices: [


        ],
        azurevoices: [
            ["lv-LV-EveritaNeural,standard,azure", "Everita [Latvian]"],
            ["lv-LV-NilsNeural,standard,azure", "Nils [Latvian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Lithuanian',
        awsvoices: [


        ],
        azurevoices: [
            ["lt-LT-LeonasNeural,standard,azure", "Leonas [Lithuanian]"],
            ["lt-LT-OnaNeural,standard,azure", "Ona [Lithuanian]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Macedonian',
        awsvoices: [

        ],
        azurevoices: [
            ["mk-MK-AleksandarNeural,standard,azure", "Aleksandar [Macedonian]"],
            ["mk-MK-MarijaNeural,standard,azure", "Marija [Macedonian]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Norwegian',
        awsvoices: [
            ["Liv,standard,aws", "Liv [Norwegian]"],
            ["Ida,neural,aws", "Ida [neural voice][Norwegian]"],


        ],
        azurevoices: [
            ["nb-NO-FinnNeural,standard,azure", "Finn [Norwegian]"],
            ["nb-NO-IselinNeural,standard,azure", "Iselin [Norwegian]"],
            ["nb-NO-PernilleNeural,standard,azure", "Pernille [Norwegian]"],

        ],
        googlevoices: [
            ["nb-NO-Standard-A, standard,googlespeech", "nb-NO-Standard-A FEMALE [Norwegian]"],
            ["nb-NO-Standard-B, standard,googlespeech", "nb-NO-Standard-B MALE [Norwegian]"],
            ["nb-NO-Standard-C, standard,googlespeech", "nb-NO-Standard-C FEMALE [Norwegian]"],
            ["nb-NO-Standard-D, standard,googlespeech", "nb-NO-Standard-D MALE [Norwegian]"],
            ["nb-NO-Standard-E, standard,googlespeech", "nb-NO-Standard-E FEMALE [Norwegian]"],
            ["nb-NO-Wavenet-A, standard,googlespeech", "nb-NO-Wavenet-A FEMALE [Norwegian]"],
            ["nb-NO-Wavenet-B, standard,googlespeech", "nb-NO-Wavenet-B MALE [Norwegian]"],
            ["nb-NO-Wavenet-C, standard,googlespeech", "nb-NO-Wavenet-C FEMALE [Norwegian]"],
            ["nb-NO-Wavenet-D, standard,googlespeech", "nb-NO-Wavenet-D MALE [Norwegian]"],
            ["nb-no-Standard-E, standard,googlespeech", "nb-no-Standard-E FEMALE [Norwegian]"],
            ["nb-no-Wavenet-E, standard,googlespeech", "nb-no-Wavenet-E FEMALE [Norwegian]"],
        ],
    },

    {
        language: 'Polish',
        awsvoices: [
            ["Ewa,standard,aws", "Ewa [Polish]"],
            ["Maja,standard,aws", "Maja [Polish]"],
            ["Jacek,standard,aws", "Jacek [Polish]"],
            ["Jan,standard,aws", "Jan [Polish]"],
            ["Ola,neural,aws", "Ola [Neural Voice][Polish]"],

        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Portuguese',
        awsvoices: [
            ["Ines,neural,aws", "Ines [neural voice][Portuguese]"],
            ["Cristiano,standard,aws", "Cristiano [Portuguese]"],



        ],
        azurevoices: [


        ],
        googlevoices: [

        ],
    },
    {
        language: 'Portuguese(Brazilian)',
        awsvoices: [
            ["Camila,neural,aws", "Camila [Neural Voice][Portuguese (Brazilian)]"],
            ["Vitória,neural,aws", "Vitória [Neural Voice][Portuguese (Brazilian)]"],
            ["Ricardo,standard,aws", "Ricardo [Portuguese (Brazilian)]"],
            ["Thiago,neural,aws", "Thiago [Neural Voice][Portuguese (Brazilian)]"],
        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Romanian',
        awsvoices: [
            ["Carmen,standard,aws", "Carmen [Romanian]"],


        ],
        azurevoices: [
            ["ro-RO-AlinaNeural,standard,azure", "Alina [Romanian]"],
            ["ro-RO-EmilNeural,standard,azure", "Emil [Romanian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Russian',
        awsvoices: [
            ["Tatyana,standard,aws", "Tatyana [Russian]"],
            ["Maxim,standard,aws", "Maxim [Russian]"],

        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Serbian',
        awsvoices: [


        ],
        azurevoices: [
            ["sr-RS-NicholasNeural,standard,azure", "Nicholas [Serbian]"],
            ["sr-RS-SophieNeural,standard,azure", "Sophie [Serbian]"],

        ],
        googlevoices: [
            ["sr-rs-Standard-A, standard,googlespeech", "sr-rs-Standard-A FEMALE [Serbian]"],


        ],
    },
    {
        language: 'Slovak',
        awsvoices: [

        ],
        azurevoices: [
            ["sk-SK-LukasNeural,standard,azure", "Lukas [Slovak]"],
            ["sk-SK-ViktoriaNeural,standard,azure", "Viktoria [Slovak]"],

        ],
        googlevoices: [

        ],
    },
    {
        language: 'Slovenian',
        awsvoices: [

        ],
        azurevoices: [
            ["sl-SI-PetraNeural,standard,azure", "Petra [Slovenian]"],
            ["sl-SI-RokNeural,standard,azure", "Rok [Slovenian]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Spanish',
        awsvoices: [
            ["Conchita,standard,aws", "Conchita [Spanish]"],
            ["Lucia,standard,aws", "Lucia [Spanish]"],
            ["Enrique,standard,aws", "Enrique [Spanish]"],
            ["Sergio,neural,aws", "Sergio[Neural Voice][Spanish]"],
        ],
        azurevoices: [
            ["es-ES-AbrilNeural,standard,azure", "Abril [Spanish]"],
            ["es-ES-AlvaroNeural,standard,azure", "Alvaro [Spanish]"],
            ["es-ES-ArnauNeural,standard,azure", "Arnau [Spanish]"],
            ["es-ES-DarioNeural,standard,azure", "Dario [Spanish]"],
            ["es-ES-EliasNeural,standard,azure", "Elias [Spanish]"],
            ["es-ES-ElviraNeural,standard,azure", "Elvira [Spanish]"],
            ["es-ES-EstrellaNeural,standard,azure", "Estrella [Spanish]"],
            ["es-ES-IreneNeural,standard,azure", "Irene [Spanish]"],
            ["es-ES-LaiaNeural,standard,azure", "Laia [Spanish]"],
            ["es-ES-LiaNeural ,standard,azure", "Lia [Spanish]"],
            ["es-ES-NilNeural,standard,azure", "Nil [Spanish]"],
            ["es-ES-SaulNeural,standard,azure", "Saul [Spanish]"],
            ["es-ES-TeoNeural,standard,azure", "Teo [Spanish]"],
            ["es-ES-TrianaNeural,standard,azure", "Triana [Spanish]"],
            ["es-ES-VeraNeural,standard,azure", "Vera [Spanish]"],



        ],
        googlevoices: [
            ["es-ES-Standard-A, standard,googlespeech", "es-ES-Standard-A FEMALE [Spanish]"],
            ["es-ES-Standard-B, standard,googlespeech", "es-ES-Standard-B MALE [Spanish]"],
            ["es-ES-Standard-C, standard,googlespeech", "es-ES-Standard-C FEMALE [Spanish]"],
            ["es-ES-Standard-D, standard,googlespeech", "es-ES-Standard-D FEMALE [Spanish]"],
            ["es-ES-Wavenet-B, standard,googlespeech", "es-ES-Wavenet-B MALE [Spanish]"],
            ["es-ES-Wavenet-C, standard,googlespeech", "es-ES-Wavenet-C FEMALE [Spanish]"],
            ["es-ES-Wavenet-D, standard,googlespeech", "es-ES-Wavenet-D FEMALE [Spanish]"],
        ],
    },
    {
        language: 'Spanish (Mexican)',
        awsvoices: [
            ["Mia,neural,aws", "Mia [Neural Voice][Spanish (Mexican)]"],
            ["Andrés,standard,aws", "Andrés [Spanish (Mexican)]"],
        ],
        azurevoices: [

        ],
        googlevoices: [


        ],
    },
    {
        language: 'Swedish',
        awsvoices: [
            ["Astrid,standard,aws", "Astrid [Swedish]"],
            ["Elin,neural,aws", "Elin [Neural Voice][Swedish]"],


        ],
        azurevoices: [
            ["sv-SE-HilleviNeural,standard,azure", "Hillevi [Swedish]"],
            ["sv-SE-MattiasNeural,standard,azure", "Mattias [Swedish]"],
            ["sv-SE-SofieNeural,standard,azure", "Sofie [Swedish]"],

        ],
        googlevoices: [
            ["sv-SE-Standard-A, standard,googlespeech", "sv-SE-Standard-A FEMALE [Swedish]"],
            ["sv-SE-Standard-B, standard,googlespeech", "sv-SE-Standard-B FEMALE [Swedish]"],
            ["sv-SE-Standard-C, standard,googlespeech", "sv-SE-Standard-C FEMALE [Swedish]"],
            ["sv-SE-Standard-D, standard,googlespeech", "sv-SE-Standard-D MALE [Swedish]"],
            ["sv-SE-Standard-E, standard,googlespeech", "sv-SE-Standard-E MALE [Swedish]"],
            ["sv-SE-Wavenet-A, standard,googlespeech", "sv-SE-Wavenet-A FEMALE [Swedish]"],
            ["sv-SE-Wavenet-B, standard,googlespeech", "sv-SE-Wavenet-B FEMALE [Swedish]"],
            ["sv-SE-Wavenet-C, standard,googlespeech", "sv-SE-Wavenet-C MALE [Swedish]"],
            ["sv-SE-Wavenet-D, standard,googlespeech", "sv-SE-Wavenet-D FEMALE [Swedish]"],
            ["sv-SE-Wavenet-E, standard,googlespeech", "sv-SE-Wavenet-E MALE [Swedish]"],
        ],
    },
    {
        language: 'Swiss',
        awsvoices: [


        ],
        azurevoices: [
            ["de-CH-JanNeural,standard,azure", "Jan [Schweiz]"],
            ["de-CH-LeniNeural,standard,azure", "Katja [Schweiz]"],

        ],
        googlevoices: [


        ],
    },

    {
        language: 'Turkish',
        awsvoices: [
            ["Filiz,standard,aws", "Filiz [Turkish]"],


        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },
    {
        language: 'Ukraine',
        awsvoices: [


        ],
        azurevoices: [
            ["uk-UA-OstapNeural,standard,azure", "Ostap [Ukraine]"],
            ["uk-UA-PolinaNeural,standard,azure", "Polina [Ukraine]"],

        ],
        googlevoices: [


        ],
    },
    {
        language: 'USA',
        awsvoices: [

            ["Ivy,neural,aws", "Ivy [neural voice][US-English]"],
            ["Joanna,neural,aws", "Joanna [neural voice][US-English]"],
            ["Kendra,neural,aws", "Kendra [neural voice][US-English]"],
            ["Kimberly,neural,aws", "Kimberly [US-English]"],
            ["Salli,neural,aws", "Salli [US-English]"],
            ["Joey,neural,aws", "Joey [US-English]"],
            ["Justin,neural,aws", "Justin [US-English]"],
            ["Kevin,neural,aws", "Kevin [US-English]"],
            ["Matthew,neural,aws", "Matthew [neural voice][US-English]"],


        ],
        azurevoices: [
            ["en-US-AmberNeural,standard,azure", "Amber [USA]"],
            ["en-US-AnaNeural,standard,azure", "Ana [USA]"],
            ["en-US-AriaNeural,standard,azure", "Aria [USA]"],
            ["en-US-AshleyNeural,standard,azure", "Ashley[USA]"],
            ["en-US-BrandonNeural,standard,azure", "Brandon [USA]"],
            ["en-US-ChristopherNeural,standard,azure", "Christopher [USA]"],
            ["en-US-CoraNeural,standard,azure", "Cora [USA]"],
            ["en-US-ElizabethNeural,standard,azure", "Elizabeth [USA]"],
            ["en-US-EricNeural,standard,azure", "Eric [USA]"],
            ["en-US-GuyNeural,standard,azure", "Guy [USA]"],
            ["en-US-JacobNeural,standard,azure", "Jacob [USA]"],
            ["en-US-JennyNeural,standard,azure", "Jenny [USA]"],
            ["en-US-MichelleNeural,standard,azure", "Michelle [USA]"],
            ["en-US-MonicaNeural,standard,azure", "Monica [USA]"],
            ["en-US-SaraNeural,standard,azure", "Sara[USA]"],
        ],
        googlevoices: [
            ["en-US-News-K, standard,googlespeech", "en-US-News-K FEMALE [USA]"],
            ["en-US-News-L, standard,googlespeech", "en-US-News-L FEMALE [USA]"],
            ["en-US-News-M, standard,googlespeech", "en-US-News-M MALE [USA]"],
            ["en-US-News-N, standard,googlespeech", "en-US-News-N MALE [USA]"],
            ["en-US-Standard-A, standard,googlespeech", "en-US-Standard-A MALE [USA]"],
            ["en-US-Standard-B, standard,googlespeech", "en-US-Standard-B MALE [USA]"],
            ["en-US-Standard-C, standard,googlespeech", "en-US-Standard-C FEMALE [USA]"],
            ["en-US-Standard-D, standard,googlespeech", "en-US-Standard-D MALE [USA]"],
            ["en-US-Standard-E, standard,googlespeech", "en-US-Standard-E FEMALE [USA]"],
            ["en-US-Standard-F, standard,googlespeech", "en-US-Standard-F FEMALE [USA]"],
            ["en-US-Standard-G, standard,googlespeech", "en-US-Standard-G FEMALE [USA]"],
            ["en-US-Standard-H, standard,googlespeech", "en-US-Standard-H FEMALE [USA]"],
            ["en-US-Standard-I, standard,googlespeech", "en-US-Standard-I MALE [USA]"],
            ["en-US-Standard-J, standard,googlespeech", "en-US-Standard-J MALE [USA]"],
            ["en-US-Wavenet-A, standard,googlespeech", "en-US-Wavenet-A MALE [USA]"],
            ["en-US-Wavenet-B, standard,googlespeech", "en-US-Wavenet-B MALE [USA]"],
            ["en-US-Wavenet-C, standard,googlespeech", "en-US-Wavenet-C FEMALE [USA]"],
            ["en-US-Wavenet-D, standard,googlespeech", "en-US-Wavenet-D MALE [USA]"],
            ["en-US-Wavenet-E, standard,googlespeech", "en-US-Wavenet-E FEMALE [USA]"],
            ["en-US-Wavenet-F, standard,googlespeech", "en-US-Wavenet-F FEMALE [USA]"],
            ["en-US-Wavenet-G, standard,googlespeech", "en-US-Wavenet-G FEMALE [USA]"],
            ["en-US-Wavenet-H, standard,googlespeech", "en-US-Wavenet-H FEMALE [USA]"],
            ["en-US-Wavenet-I, standard,googlespeech", "en-US-Wavenet-I MALE [USA]"],
            ["en-US-Wavenet-J, standard,googlespeech", "en-US-Wavenet-J MALE [USA]"],
        ],

    },


    {
        language: 'Welsh',
        awsvoices: [
            ["Gwyneth,standard,aws", "Gwyneth [Welsh]"],


        ],
        azurevoices: [


        ],
        googlevoices: [


        ],
    },






]

export default sprachen;