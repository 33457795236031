<template>

    <div id="app" class="has-background-download">

        <section class="hero">
            <img :src="headersource" @error="headersource=require('@/assets/VAD.io_creatorj.png')"/>
<!--          <img :src="'https://comevismixsrc.s3.eu-central-1.amazonaws.com/' + user.username + '/header/header.png'" @error="imageUrlAlt">-->
        </section>

        <div class="container " style="padding-bottom: 25px">

            <amplify-authenticator>


                  <amplify-sign-in
                      header-text="VAD.io Creator by comevis - Login"
                      slot="sign-in"
                      hide-sign-up="true"
                  ></amplify-sign-in>



                <!--            <img src="./assets/VAD.io_creatorj.png"/>-->
<!--                <img :src="'https://comevismixsrc.s3.eu-central-1.amazonaws.com/' + user.username + '/header/header.png'" @error="imageUrlAlt">-->

                <div class="container">

                    <div class="welcome has-text-downloadinvert">
                        <div class="tile level-left">
                            <h1>Hello, {{user.username}}! You have generated {{counter}} audio modules.</h1>

                        </div>


                        <div class="tile level-right">
                            <!--                        <div class="signout">-->
                            <amplify-sign-out></amplify-sign-out>
                            <!--                        </div>-->

                        </div>

                    </div>
                </div>

                <p class="m-2">

                </p>
                        <section>

                            <div class="container" style="margin-bottom: 25px">
                                <div class="box">
<!--                                  Ab hier Voice -->
                                    <div class="content">

                                        <p>
                                            <strong>#1 Text input</strong>
                                            <br>
                                          Maximum length of voice output depending on your individual sound moods.
                                          <br> For help please contact: helpcreator@comevis.com
                                          <br> SSML functionality depending on the selected voice model. Documentation, templates & tutorials can be found <a href="https://www.cloud.comevis.com/creator-docu" target="_blank">here</a>.
                                        </p>
                                    </div>



                                  <!-- Bis hier Voice -->




                                  <div v-for="(input, index) in inputs"  :key="index">
<!--                                    <input name="data" v-model="inputs[index]"  />-->

                                    <!--                                  Ab hier Voice -->
                                    <div style="margin-top: 40px" class="columns is-centered" >

                                      <p>
                                        <strong>Voice: {{index+1}}</strong>

                                      </p>
                                    </div>


                                    <b-tabs v-model="input.activeTab">
                                      <b-tab-item label="Voice Text">

                                        <b-input :id="'textVoiceEntry'+ (index)" type="textarea" placeholder="Please enter your text here!"> </b-input>


                                      </b-tab-item>

                                      <b-tab-item label="SSML Text">
                                        <h6 v-if="input.voice.split(',')[2] == 'aws'" class="title is-7" style="margin-bottom: 8px; font-weight: bold;">AWS-SSML-Shortcuts:</h6>
                                        <h6 v-if="input.voice.split(',')[2] == 'azure'" class="title is-7" style="margin-bottom: 8px; font-weight: bold;">Azure-SSML-Shortcuts:</h6>
                                        <h6 v-if="input.voice.split(',')[2] == 'googlespeech'" class="title is-7" style="margin-bottom: 8px; font-weight: bold;">Google-SSML-Shortcuts:</h6>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;500ms&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">short break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;1s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;2s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">medium break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;3s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">long break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;lang xml:lang=&quot;en-US&quot;&gt;Your Text&lt;/lang&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">EN-US pronunciation</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;lang xml:lang=&quot;de-DE&quot;&gt;Your Text&lt;/lang&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">DE pronunciation</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;lang xml:lang=&quot;it-IT&quot;&gt;Your Text&lt;/lang&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">IT pronunciation</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;say-as interpret-as=&quot;telephone&quot;&gt;0221 177 339 70&lt;/say-as&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">interpret as telephone number</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'aws'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;say-as interpret-as=&quot;date&quot;&gt;11.11.2050&lt;/say-as&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">interpret as date</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'azure'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;lang xml:lang=&quot;en-US&quot;&gt;Your Text&lt;/lang&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">EN-US pronunciation</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'azure'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;500ms&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">short break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'azure'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;1s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'azure'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;2s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">mediumn break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'azure'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;3s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">long break</strong></span></b-button>

                                        <b-button v-if="input.voice.split(',')[2] == 'googlespeech'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;lang xml:lang=&quot;en-US&quot;&gt;Your Text&lt;/lang&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">EN-US pronunciation</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'googlespeech'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;500ms&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">short break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'googlespeech'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;1s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'googlespeech'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;2s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">medium break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'googlespeech'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;3s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">long break</strong></span></b-button>

                                        <b-button v-if="input.voice.split(',')[2] == 'elevenvoices'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;500ms&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">short break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'elevenvoices'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;1s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'elevenvoices'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;2s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">medium break</strong></span></b-button>
                                        <b-button v-if="input.voice.split(',')[2] == 'elevenvoices'" type="is-info is-light" size="is-small"  @click="insertSomething(index,'&lt;break time=&quot;3s&quot;/&gt;')" style="margin-bottom: 15px; margin-left: 15px; "><span><strong style="vertical-align: middle;">long break</strong></span></b-button>

                                        <b-input v-if="input.voice.split(',')[2] == 'aws'" :id="'ssmlVoiceEntry'+ (index)"   type="textarea" v-model="input.text"  placeholder="Please enter your SSML tagged text here! NEW: A <speak> tag is no longer required!"> </b-input>
                                        <b-input v-if="input.voice.split(',')[2] == 'azure'" :id="'ssmlVoiceEntry'+ (index)"   type="textarea" v-model="input.text"  placeholder="Please enter your SSML tagged text here! NEW: A <speak> tag is no longer required!"> </b-input>
                                        <b-input v-if="input.voice.split(',')[2] == 'googlespeech'" :id="'ssmlVoiceEntry'+ (index)"   type="textarea" v-model="input.text"  placeholder="Please enter your SSML tagged text here! NEW: A <speak> tag is no longer required!"> </b-input>
                                        <b-input v-if="input.voice.split(',')[2] == 'elevenvoices'" :id="'ssmlVoiceEntry'+ (index)"   type="textarea" v-model="input.text"  placeholder="Please enter your SSML tagged text here! NEW: A <speak> tag is no longer required!"> </b-input>

                                        <!--

                                                                            <textarea ref="yourTextarea" v-model.trim="txtContent"></textarea>-->


                                      </b-tab-item>
<!--                                      <div class="level-right" style ="margin-top: 15px">
                                        <b-button type="is-info" size="is-small" @click="speakText(index)"> <b-icon icon="play"  style="vertical-align: middle;"></b-icon><span><strong style="vertical-align: middle;">Speech output test</strong></span></b-button>

                                      </div>-->

                                    </b-tabs>
<!--                                    <div class="level-right">
                                      <b-button type="is-info" size="is-small" style="margin-right: 5px;" @click="speakText(index)"> <b-icon icon="play"  style="vertical-align: middle;"></b-icon><span><strong style="vertical-align: middle;">Speech output test</strong></span></b-button>

                                    </div>-->
<!--                                    <nav class="level" style="margin-top: -20px">-->

                                      <!-- Left side -->
<!--                                      <div class="level-left">-->
                                        <div class="block">
                                          <strong> #2 Language/Voice selection</strong>

                                          <br>
                                          <b-field grouped group-multiline>
                                              <b-field >

                                                <b-select required id="sprachen" name="sprachen" v-model="input.selectedSprache" :key="index">
                                                  <option value="" disabled selected>Choose Language</option>
                                                  <!-- <option v-bind:value="sprache.language" v-for="(sprache, key) in sprachen"
                                                         v-bind:key="key">{{ sprache.language }}</option> -->
                                                 <option v-bind:value="sprache.language" v-for="(sprache, key) in filteredSprachen()" v-bind:key="key">
                                                   {{ sprache.language }}
                                                 </option>
                                               </b-select>
                                             </b-field>
                                           <b-field >
                                               <b-select required id="final_voice" name="final_voice" v-model="input.voice" :key="index">
<!--                                                <select required id="final_voice" name="final_voice" v-model="selectedFinalVoice">-->
                                                  <option value="" disabled selected>Choose Voice</option>
                                                  <optgroup v-if="input.selectedSprache !== 'Premium/Multilingual'" label="AWS">
                                                  <option v-bind:value="finalVoice[0]" v-for="(finalVoice, key) in getFinalAWSSprachen(index)"
                                                          v-bind:key="key">{{ finalVoice[1] }}</option>
                                                  </optgroup>
                                                  <optgroup v-if="input.selectedSprache !== 'Premium/Multilingual'" label="Azure">
                                                    <option v-bind:value="finalVoice[0]" v-for="(finalVoice, key) in getFinalAzureSprachen(index)"
                                                            v-bind:key="key">{{ finalVoice[1] }}</option>
                                                  </optgroup>
                                                  <optgroup v-if="input.selectedSprache !== 'Premium/Multilingual'" label="Google">
                                                    <option v-bind:value="finalVoice[0]" v-for="(finalVoice, key) in getFinalGoogleSprachen(index)"
                                                            v-bind:key="key">{{ finalVoice[1] }}</option>
                                                  </optgroup>

                                                  <optgroup v-if="input.selectedSprache === 'Premium/Multilingual'" label="Premium/Multilingual">
                                                    <option v-bind:value="finalVoice[0]" v-for="(finalVoice, key) in getFinalElevenSprachen(index)"
                                                            v-bind:key="key">{{ finalVoice[1] }}</option>
                                                  </optgroup>
<!--                                                </select>-->
                                                </b-select>
                                              </b-field>


                                            <b-button :style="{'margin-left': 'auto'}" :type="'is-info'" size="is-small" @click="isPlaying ? stopTestSpeech() : speakText(index)">
                                              <b-icon :icon="isPlaying ? 'stop' : 'play'" style="vertical-align: middle;"></b-icon>
                                              <span><strong style="vertical-align: middle;">{{ isPlaying ? 'Stop' : 'Speech output test' }}</strong></span>
                                            </b-button>

                                          </b-field>

                                        </div>
<!--                                      </div>-->



<!--                                    </nav>-->
                                    <!-- Bis hier Voice -->

                                  </div>
                                  <div style="margin-top: 20px;" class="columns is-centered">

                                    <b-button class="has-text-centered" type="is-info" size="is-small" style="margin-top: 30px;"  @click="addInput"> <b-icon icon="plus"  style="vertical-align: middle;"></b-icon><span><strong style="vertical-align: middle;">Add Voice</strong></span></b-button>

                                    <b-button v-if="inputs.length>1" class="has-text-centered" type="is-info" size="is-small" style="margin-left: 20px; margin-top: 30px;"  @click="removeInput"> <span><strong style="vertical-align: middle;">Remove Voice</strong></span></b-button>
                                  </div>







                                    <div class="content">


                                            <strong>#3 Advanced Audio Processing</strong>
                                            <br>



                                      Select a matching sound masking, file format & mastering/generation method.<br>
                                            <div class="columns">
                                                <div class="column is-half">
                                                    <div class="content">

<!--                                                        <strong>Text</strong>-->
<!--    -->
<!--                                                        <div class="content">-->
<!--                                                            {{ props.row.text }}-->
<!--                                                        </div>-->
                                                      <b-field grouped>

                                                        <b-field label="Intro" v-if="intromoodList && intromoodList.length > 1">
                                                          <b-select placeholder="Wähle Intro" class="selectpicke" v-model="selectedintro" id="introSelected" @input="changeIntroSound" :style="{ maxWidth: '100px' }">
                                                            <option v-for="dept in intromoodList" :key="dept.Key" v-bind:value="dept.value">
                                                              {{dept.Key}}
                                                            </option>
                                                          </b-select>
                                                        </b-field>
                                                        <b-field id="intromoodpreview" label="Intro Preview:" style="display:none;" >
                                                          <br>
                                                          <div id="intromooddiv" style="display:none; margin-left:5px;">

                                                            <audio id="previewintrosound" oncontextmenu="return false;" controls  controlslist="nodownload"></audio>


                                                          </div>
                                                        </b-field>
                                                      </b-field>

                                                        <b-field grouped>

                                                          <b-field label="Soundmasking">
                                                            <b-select placeholder="Wähle ein Soundmood" class="selectpicke" v-model="selected" id="soundSelected" @input="changeSound" :style="{ maxWidth: '250px' }">
                                                              <option v-for="dept in soundmoodList" :key="dept.Key" v-bind:value="dept.value">
                                                                {{dept.Key}}
                                                              </option>
                                                            </b-select>
                                                          </b-field>
                                                          <b-field id="soundmoodpreview" label="Soundmood Preview:" style="display:none;" >
                                                            <br>
                                                            <div id="audiomooddiv" style="display:none; margin-left:5px;">

                                                              <audio id="sound1" oncontextmenu="return false;" controls  controlslist="nodownload"></audio>
                                                              <!--                                                        <AudioPlayer :audioSrc="'https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1'" />-->


                                                            </div>
                                                          </b-field>


                                                        </b-field>

                                                      <b-field grouped>

                                                        <b-field label="Outro" v-if="outromoodList && outromoodList.length > 1">
                                                          <b-select placeholder="Wähle ein Soundmood" class="selectpicke" v-model="selectedoutro" id="outroSelected" @input="changeOutroSound" :style="{ maxWidth: '100px' }">
                                                            <option v-for="dept in outromoodList" :key="dept.Key" v-bind:value="dept.value">
                                                              {{dept.Key}}
                                                            </option>
                                                          </b-select>
                                                        </b-field>
                                                        <b-field id="outromoodpreview" label="Outro Preview:" style="display:none;" >
                                                          <br>
                                                          <div id="outromooddiv" style="display:none; margin-left:5px;">

                                                            <audio id="previewoutrosound" oncontextmenu="return false;" controls  controlslist="nodownload"></audio>


                                                          </div>
                                                        </b-field>
                                                      </b-field>








                                                    </div>
                                                </div>
                                                <div class="column">
                                                    <b-field grouped label="File format">
                                                        <b-radio v-model="radio"
                                                                 name="name"
                                                                 native-value="mp3"
                                                                 size="is-small"
                                                                 type="is-primary">
                                                            mp3
                                                        </b-radio>
                                                        <b-radio v-model="radio"
                                                                 name="name"
                                                                 native-value="pcm_s16le"
                                                                 size="is-small"
                                                                 type="is-primary">
                                                            pcm 16bit 8khz mono
                                                        </b-radio>
                                                        <b-radio v-model="radio"
                                                                 name="name"
                                                                 native-value="pcm_alaw"
                                                                 size="is-small"
                                                                 type="is-primary">
                                                            pcm a-law

                                                        </b-radio>
                                                        <b-radio v-model="radio"
                                                                 name="name"
                                                                 native-value="pcm_mulaw"
                                                                 size="is-small"
                                                                 type="is-primary">
                                                            pcm u-law

                                                        </b-radio>
                                                        <b-radio v-model="radio"
                                                                 name="name"
                                                                 native-value="wma"
                                                                 size="is-small"
                                                                 type="is-primary">
                                                          wma
                                                        </b-radio>


                                                    </b-field>

                                                    <section>
                                                      <b-field label="Mastering">
                                                        <b-radio-button v-model="volumegain"
                                                                        native-value="0"
                                                                        type="is-primary is-light is-outlined">

                                                          <span>IVR/Bot</span>
                                                        </b-radio-button>

                                                        <b-radio-button v-model="volumegain"
                                                                        native-value="3"
                                                                        type="is-primary is-light is-outlined">

                                                          <span>Podcast</span>
                                                        </b-radio-button>

                                                        <b-radio-button v-model="volumegain"
                                                                        native-value="6"
                                                                        type="is-primary is-light is-outlined">
                                                          <span>Web/Video</span>
                                                        </b-radio-button>

                                                        <b-radio-button v-model="volumegain"
                                                                        native-value="10"
                                                                        type="is-primary is-light is-outlined">
                                                          <span>Social/Ads/POS</span>
                                                        </b-radio-button>
                                                      </b-field>
                                                      <p class="content">

                                                      </p>
                                                    </section>

                                                  <b-field grouped label="Generation method">
                                                    <template #label>
                                                      Generation method
                                                      <b-tooltip type="is-dark" label="Choose between generating an individual audio file for each voice, or merging all voices into one audio file.">
                                                        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                                                      </b-tooltip>
                                                    </template>

                                                    <b-switch v-model="isSwitchedCustom"
                                                              size="is-small"
                                                              true-value="Merge Voice Generierung"
                                                              false-value="Einzel Generierung"
                                                              type="is-primary"
                                                              passive-type="is-primary"
                                                              outlined="true">
                                                      {{ isSwitchedCustom }}
                                                    </b-switch>
                                                  </b-field>



                                                </div>

                                            </div>




                                            <!--                        https://catswhocode.com/html-audio-tag/-->


<!--                                        </p>-->

                                        <b-collapse :open="false" aria-id="contentIdForA11y1">
                                            <a slot="trigger" slot-scope="props" aria-controls="contentIdForA11y1">
                                                <b-icon :icon="!props.open ? 'menu-right' : 'menu-down'"  style="vertical-align: middle;"></b-icon>
                                                <strong>{{ !props.open ? 'Pro Audio Features' : 'Pro Audio Features' }}</strong>
                                                <div class="divider" style=" margin: 2px"></div>
                                            </a>
                                          Edit ratio, threshold and fade-in/fade-out times of the mixing and compression process for specific applications.
                                            <br>
                                            <div class="tile is-ancestor">
                                                <div class="tile is-4 is-parent margin-right-10" style="margin-left: 40px;">
                                                    <article class="tile is-child">





                                                        <strong>Voice presence</strong>
                                                        <b-tooltip  type="is-info" multilined>
                                                            <b-icon
                                                                    icon="help-circle"
                                                                    size="is-small">
                                                            </b-icon>
                                                            <template v-slot:content>
                                                                <b>Voice presence</b><br>
                                                              Please note that changing this setting can lead to reduced intelligibility of the speech. Please contact us to determine the best values for your use case.
                                                            </template>
                                                        </b-tooltip>

                                                        <b-field >

                                                            <b-slider  type="is-info" v-model="thresholdvalue" size="is-medium" :step="0.0001" :min="0.00098" :max="0.1" :tooltip-type="sliderType" :custom-formatter="val => Math.round(100 - ((val - 0.00098) * 100) / (0.1 - 0.00098)) + '%'">
                                                              <b-slider-tick :value="0.00098">100</b-slider-tick>
                                                              <b-slider-tick :value="0.1">0</b-slider-tick>
                                                            </b-slider>
                                                        </b-field>



                                                    </article>

                                                </div>
                                                <div class="tile is-1 is-parent">
                                                </div>
                                                <div class="tile is-2 is-parent">
                                                    <article class="tile is-child">
                                                        <b-field label="Soundmasking Fade-IN Time (Seconds)">
                                                            <b-numberinput v-model="fadeinvalue" type="is-info" size="is-small" controls-position="compact"  :step="0.1" controls-rounded></b-numberinput>
                                                        </b-field>


                                                    </article>

                                                </div>
                                                <div class="tile is-2 is-parent">
                                                    <article class="tile is-child">


                                                        <b-field label="Soundmasking Fade-OUT Time (Seconds)">
                                                            <b-numberinput v-model="fadeoutvalue" type="is-info" size="is-small" controls-position="compact" :step="0.1" controls-rounded></b-numberinput>
                                                        </b-field>
                                                    </article>
                                                </div>
                                            </div>
                                        </b-collapse>

                                    </div>







                                </div>


                                <nav class="level">
                                    <!--                <div class="level-left">-->
                                    <!--                -->
                                    <!--                </div>-->
                                    <!--                <div class="level-right">-->

                                  <div class="buttons level-item">
                                    <div>

                                      <!--                                        generation_toast();-->
                                      <p><button @click='addTodo(); generation_toast();'  class='button is-info' style="background-color: #ff2e2b"><strong>Generate final file</strong></button></p>
                                      <p style="text-align:center"><vue-ellipse-progress v-show="showprogress" :loading="true" :size="65" :thickness="5"  /></p>
                                    </div>
                                  </div>

                                  <!--                </div>-->
                                </nav>





                            </div>


                        </section>
<!--                <InputField/>-->

                <div id="results"> </div>

                <container>
<!--                    <box>-->
<!--                        <b-table class="myTable" :data="data" :columns="columns" ></b-table>-->
<!--                    </box>-->


                        <b-table
                                :data="finaldata"
                                :columns="columns"
                                ref="table"
                                paginated
                                per-page="5"
                                :opened-detailed="defaultOpenedDetails"
                                detailed
                                detail-key="id"
                                :show-detail-icon="showDetailIcon"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">

<!--                            <b-table-column field="id" label="ID">-->

<!--                            </b-table-column>-->

<!--                            <b-table-column field="voice" label="Voice">-->

<!--                            </b-table-column>-->

<!--                            <b-table-column field="soundmood" label="Soundmood">-->

<!--                            </b-table-column>-->

<!--                            <b-table-column field="url" label="URL" >-->

<!--                            </b-table-column>-->



                            <template slot="detail" slot-scope="props">

<!--                                        <div class="content">-->
<!--                                            <p>-->
<!--                                                <strong>Text</strong>-->

<!--                                                <br>-->
                                        <div class="columns">

                                            <div class="column is-one-quarter">
                                                    <strong>ID</strong>

                                                    <div class="content">
                                                        {{ props.row.id }}
                                                    </div>
                                            </div>



                                            <div class="column"><strong>Threshold</strong><div class="content"> {{ props.row.threshold }}</div></div>
                                            <div class="column"><strong>FadeIn</strong><div class="content"> {{ props.row.fadein}}</div></div>
                                            <div class="column"><strong>FadeOut</strong><div class="content"> {{ props.row.fadeout }}</div></div>

                                        </div>

                            </template>
                        </b-table>
                </container>



            </amplify-authenticator>
            <footer class="footer has-background-download" style="padding-bottom: 2rem">
                    <div class="columns" >
                        <div class="column is-narrow">
                            <a href="https://www.comevis.com/impressum">Impressum</a>
                        </div>
                        <div class="column is-narrow">
                            <a href="https://www.comevis.com/datenschutz">Datenschutz / Nutzungsbedingungen</a>

                        </div>
                      <div class="column is-narrow">
                        <p style="color: white;">Audio licence according to your C-Care agreement</p>

                      </div>

                    </div>
                    ©2002-2024 comevis
            </footer>
        </div>

    </div>

</template>


<script>
    import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
    import {API} from 'aws-amplify';
    import * as sdk from "microsoft-cognitiveservices-speech-sdk";

    import sprachen from "./data.js";
    //import AudioPlayer from './components/AudioPlayer.vue';
    // import {AxiosInstance as axios} from "axios";
    //import HelloWorld from './components/HelloWorld.vue'
    // import Soundfiletable from './components/Soundfiletable.vue'

    // import InputField from './components/InputField.vue'



    const AWS = window.AWS;

    AWS.config.region = 'eu-central-1'; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'eu-central-1:21ea852e-2b21-4d04-b495-18ec224a5891',
    });
    const s3 = new AWS.S3();
    //https://docs.amazonaws.cn/en_us/sdk-for-javascript/v2/developer-guide/getting-started-browser.html

    export default {
        name: 'app',
        components: {
            //HelloWorld,
            //InputField,
            // Soundfiletable,

            //AudioPlayer,


        },


      computed: {




        },
        data() {
            return {

              sprachen: sprachen,
              selectedSprachen: [],
              selectedFinalVoice: [],
                finaldata: [

                ],
              inputs: [{text: "", textazure: " ", activeTab: 0, counter: 0,
                voice: "Vicki,standard,aws", selectedSprache: "", elevenlastcreatedtext: "",
                elevenlastcreatedvoice: "",
                elevenaudiokey: "", voicename: ""}],

                datavoices: [],
                columns: [

                  {
                    field: 'text',
                    label: 'First Text',
                    // numeric: true
                  },
                    {
                        field: 'soundmood',
                        label: 'Main Soundmoodmasking',
                    },
                    {
                        field: 'voice',
                        label: 'First Voice',
                    },
                    {
                        field: 'url',
                        label: 'Temporary preview & download ',
                        // centered: true
                    },

                ],

                user: {},
                prefix : "",
                counter: "",
                lastTodoId: "",
                lastSoundId: "",
                sliderValue: "4",
                radio: 'mp3',
                ratiovalue: 10,
                thresholdvalue: 0.0015,
                fadeinvalue: 1,
                fadeoutvalue: 1,
                selectedvoicestatic: "Vicki",
                selectedvoice: [],
                soundmoodList: [],
                intromoodList: [],
                outromoodList: [],
                soundmoodLoaded: false,
                selected: "",
                selectedintro: "",
                selectedoutro: "",
                headersource: require('@/assets/VAD.io_creatorj.png'),

                isSwitchedCustom: 'Merge Voice Generierung',
                showprogress: false,
                elevenlastcreatedtext: "",
                elevenlastcreatedvoice: "",
                elevenaudiokey: "",
                elevenvoicesdynamic: [],
                silenceoption: false,
                volumegain: '0',
                isPlaying: false,
                // activeTab: 0,




            }

        },
        mounted(){
            // this.getSoundmoods();
        },


        created() {
            // authentication state managament
            onAuthUIStateChange((state, user) => {
                // set current user and load data after login
                if (state === AuthState.SignedIn) {
                    this.user = user;
                    this.headersource = "https://comevismixsrc.s3.eu-central-1.amazonaws.com/" + this.user.username + "/header/header.png";
                    this.getUsercount();
                  this.getSoundmoods();

                    this.getIntromoods();
                    this.getOutromoods();
                    // this.prefix = user + "/";

                    console.log(user);

                  window.audio = new Audio();
                  console.log("hello friend" + this.logitems);
                }
            });



        },
        watch: {
          silenceoption: function () {
            this.getSoundmoods();
          }
        },
        methods: {

          filteredSprachen() {
            if (this.user.username === 'Swisscom') {
              return this.sprachen.filter(sprache => sprache.language.startsWith('Swisscom'));
              //return this.sprachen.filter(sprache => sprache.language === 'Bosnian');
              // Gibt nur "Swisscom" zurück, wenn der Benutzer Stefan ist
            } else {
              return this.sprachen.filter(sprache => !sprache.language.startsWith('Swisscom'));
              // Filtert Bosnian aus, wenn der Benutzer nicht Stefan ist
            }
          },

          stopTestSpeech() {
            if (window.audio) {
              window.audio.pause(); // Pausiert das Audio
              window.audio.currentTime = 0; // Setzt die Wiedergabezeit auf den Anfang zurück
              this.isPlaying = false;
            }
          },

          getDisplayElevenVoice(vid) {


            let result;
            for (let key in this.elevenvoicesdynamic) {
              if(vid === this.elevenvoicesdynamic[key]) {
                console.log("found key value match: " + this.elevenvoicesdynamic[key]);
                result = key;
              }
            }

            if (result) {
              console.log("Key found:", + result); // logs the key of the found element
              return result; // returns the key
            } else {
              console.log('No matching element found');
              return null; // or handle this case as needed
            }
          },
          getFinalAWSSprachen(index) {
            if (!this.inputs[index].selectedSprache.length) {
              console.log("selektierte sprachen" + this.inputs[index].selectedSprache.length);
              return [];
            }

            var finalVoices = this.sprachen.find(p => p.language === this.inputs[index].selectedSprache).awsvoices;
            console.log("final voices" + finalVoices);
            return finalVoices;
          },
          getFinalAzureSprachen(index) {
            if (!this.inputs[index].selectedSprache.length) {
              console.log("selektierte sprachen" + this.inputs[index].selectedSprache.length);
              return [];
            }

            var finalVoices = this.sprachen.find(p => p.language === this.inputs[index].selectedSprache).azurevoices;
            console.log("final voices" + finalVoices);
            return finalVoices;
          },
          getFinalGoogleSprachen(index) {
            if (!this.inputs[index].selectedSprache.length) {
              console.log("selektierte sprachen" + this.inputs[index].selectedSprache.length);
              return [];
            }

            var finalVoices = this.sprachen.find(p => p.language === this.inputs[index].selectedSprache).googlevoices;
            console.log("final voices" + finalVoices);
            return finalVoices;
          },
          getFinalElevenSprachen(index) {
            if (!this.inputs[index].selectedSprache.length) {
              console.log("selektierte sprachen" + this.inputs[index].selectedSprache.length);
              return [];
            }
            var finalVoices = this.convertToElevenVoicesArray(this.elevenvoicesdynamic);
            //var finalVoices = this.sprachen.find(p => p.language === this.inputs[index].selectedSprache).elevenvoices;
            console.log("final voices eleven" + finalVoices);
            return finalVoices;
          },
          convertToElevenVoicesArray(dynamoMap) {
            let result = [];
            for (let key in dynamoMap) {
              console.log("Key:", key, "Value:", dynamoMap[key]);
              result.push([`${dynamoMap[key]}, standard,elevenvoices`, key]);
            }

            return result;
          },

          generation_toast() {
            this.$buefy.toast.open({
              duration: 2500,
              message: `Advanced Audio processing started...`,
              position: 'is-top',
              type: 'is-info'
            })
          },
          generation_error_toast() {
            this.$buefy.toast.open({
              duration: 2500,
              message: `Error: Please check if your input text matches the requirements!`,
              position: 'is-top',
              type: 'is-danger'
            })
          },
          generation_api_error_toast() {
            this.$buefy.toast.open({
              duration: 2500,
              message: `Error: Unable to connect to backend! Please check browser logs for details.`,
              position: 'is-top',
              type: 'is-danger'
            })
          },

          imageUrlAlt(event) {
            event.target.src = "./assets/VAD.io_creatorj.png"
          },

          // Insert SSMl Tag Funktion
          insertSomething(index, value) {

                var textArea = document.getElementById("ssmlVoiceEntry"+ index);
                console.log(textArea);
                var startPos = textArea.selectionStart,
                    // get cursor's position:
                    endPos = textArea.selectionEnd,

                    tmpStr = textArea.value;
            console.log(startPos);
                if (value === null) {
                    return;
                }

                // insert:
            // document.getElementById("ssmlVoiceEntry"+ index).value = tmpStr.substring(0, startPos) + value + tmpStr.substring(endPos, tmpStr.length);
            this.inputs[index].text = tmpStr.substring(0, startPos) + value + tmpStr.substring(endPos, tmpStr.length);

          },

          // Add Voice
          addInput() {
            this.inputs.push({text: "", activeTab: 0, counter: this.inputs.length+1, voice: "Vicki,standard,aws",  selectedSprache: "German"})
          },
          // Remopve Voice
          removeInput() {
            this.inputs.pop();
          },



        getVoices: function() {
                var polly = new AWS.Polly({apiVersion: '2016-06-10'});
                polly.describeVoices({}, function(err, data) {
                    if (err) console.log(err, err.stack); // an error occurred
                    else {
                        // console.log(data);           // successful response
                        // this.datavoices = this.datavoices.concat(data.Voices.flat());
                        // data.Voices.forEach(element => this.datavoices.push(element));
                        console.log(data);
                        this.datavoices = [
                            {Id: 1, label: 'value1'},
                            {Id: 2, label: 'value2'}
                        ];
                        console.log(this.datavoices);
                    }
                    //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Polly.html#describeVoices-property
                });
            },

          // Speak Polly Text
            speakText: function(index) {


                var voicetext = document.getElementById("textVoiceEntry" + index).value;
                var voiceoption = "text";

                if (this.inputs[index].activeTab == 1) {

                    voiceoption = "ssml";

                }
                var params = {

                    OutputFormat: "mp3",
                    // SampleRate: "16000",
                    Text: voicetext,
                    TextType: voiceoption,
                    VoiceId: "Vicki",
                    Engine: "standard",

                };
                // var valuetmp = document.getElementById("this.selectedvoice").value;
              //todo selectedvoice als array
                var valuetmp = this.inputs[index].voice;
                var split = valuetmp.split(",");
                params.VoiceId = split[0];
                params.Engine= split[1];
                var cloudprovider = split[2];


                // params.VoiceId = this.selectedvoice;


                if (cloudprovider == "aws") {
                  var prespeaktag = "<speak> ";
                  var postspeaktag =" </speak>";
                  if (this.inputs[index].activeTab == 1) {
                    params.Text =  prespeaktag + document.getElementById("ssmlVoiceEntry"+ index).value + postspeaktag;
                  }
                  var polly = new AWS.Polly({apiVersion: '2016-06-10'});
                  let vm = this;
                  polly.synthesizeSpeech(params, function(err, data) {
                    if (err) console.log(err, err.stack); // an error occurred
                    else     {
                      var blob = new Blob([data.AudioStream], { type: 'audio/mpeg' });
                      var url = window.URL.createObjectURL(blob)
                      // window.audio = new Audio();
                      window.audio.src = url;
                      window.audio.onended = () => {
                        console.log("Das Audio ist zu Ende gespielt.");
                        // Hier können Sie weitere Aktionen durchführen, z.B. den Abspielstatus aktualisieren
                        vm.isPlaying = false;
                      };
                      window.audio.play();
                      vm.isPlaying = true;
                      //console.log(data);
                    }           // successful response
                    /*
                    data = {
                     AudioStream: <Binary String>,
                     ContentType: "audio/mpeg",
                     RequestCharacters: 37
                    }
                    */
                  });
                } else if(cloudprovider == "azure") {


                    const speechConfig = sdk.SpeechConfig.fromSubscription("143c0f849cce4590be774736743cfe78", "westeurope");
                    speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3;
                    speechConfig.speechSynthesisVoiceName =  params.VoiceId;
                  // const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();

                    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, null);
                        if(voiceoption != "ssml") {
                          synthesizer.speakTextAsync(
                              params.Text,
                              result => {
                                const audioData = result.audioData;
                                console.log(`Audio data byte size: ${audioData.byteLength}.`);

                                // Erstellen eines Blobs aus dem Audio-ArrayBuffer
                                const audioBlob = new Blob([audioData], { type: 'audio/mp3' });

                                // Erstellen einer Blob-URL
                                const audioUrl = URL.createObjectURL(audioBlob);

                                window.audio.src = audioUrl;

                                window.audio.onended = () => {
                                  console.log("Das Audio ist zu Ende gespielt.");
                                  // Hier können Sie weitere Aktionen durchführen, z.B. den Abspielstatus aktualisieren
                                  this.isPlaying = false;
                                };
                                window.audio.play();
                                this.isPlaying = true;
                                synthesizer.close();
                                //return result.audioData;
                              },
                              error => {
                                console.log(error);
                                synthesizer.close();
                              });
                        } else {
                          var ssmlpre = "<speak version=\"1.0\" xmlns=\"https://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"https://www.w3.org/2001/mstts\" xml:lang=\"en-US\"> <voice name=\"" + params.VoiceId + "\"> "
                          var ssmlpost = "</voice> </speak>";
                          var ssmltext =  ssmlpre + document.getElementById("ssmlVoiceEntry"+ index).value + ssmlpost;
                          synthesizer.speakSsmlAsync(

                              ssmltext,
                              result => {
                                if (result.errorDetails) {
                                  console.error(result.errorDetails);
                                } else {
                                  console.log(JSON.stringify(result));
                                }
                                const audioData = result.audioData;
                                console.log(`Audio data byte size: ${audioData.byteLength}.`);

                                // Erstellen eines Blobs aus dem Audio-ArrayBuffer
                                const audioBlob = new Blob([audioData], { type: 'audio/mp3' });

                                // Erstellen einer Blob-URL
                                const audioUrl = URL.createObjectURL(audioBlob);

                                window.audio.src = audioUrl;

                                window.audio.onended = () => {
                                  console.log("Das Audio ist zu Ende gespielt.");
                                  // Hier können Sie weitere Aktionen durchführen, z.B. den Abspielstatus aktualisieren
                                  this.isPlaying = false;
                                };
                                window.audio.play();
                                this.isPlaying = true;
                                synthesizer.close();

                              },
                              error => {
                                console.log(error);
                                synthesizer.close();
                              });
                        }

                  } else if(cloudprovider == "googlespeech") {
                      var voiceID = split[0];
                      var splitlanguage = split[0].split("-");
                      var languagecode = splitlanguage[0] + "-" + splitlanguage[1];
                      var googletext = voicetext;
                      var googleprespeaktag = "<speak> ";
                      var googlepostspeaktag =" </speak>";

                      if (this.inputs[index].activeTab == 1) {
                        googletext = googleprespeaktag + document.getElementById("ssmlVoiceEntry"+ index).value + googlepostspeaktag;
                      }
                      API.post('vuemixApi', `/texttospeech`, {
                        body: {


                          speechtext: googletext,
                          languageCode: languagecode,
                          name: voiceID,
                          inputencoding : 'LINEAR16'

                        }
                      }).then(result => {
                        //var audiourl = JSON.parse(result);
                        console.log(result.message.Location);

                        //Todo: lastSoundId Liste mit den Ids der vorher aufgerufenen PostRequests erstellen, um Reihenfolge zu gewährleisten oder aber sogar PostRequests als Promise All Resolve Sequentiell gestalten
                        //ggf lastSoundId get abfrage anpassen das sie auf liste zugreift
                        //this.lastSoundId = JSON.parse(result.body).id;
                        // setTimeout(function(){ this.getSounds(); },3000);
                        //this.getSound(JSON.parse(result.body).id, 1);
                        // this.getUsercount();
                        var audiourl = result.message.Location;
                        //var url = window.URL.createObjectURL(blob)
                        // window.audio = new Audio();
                        window.audio.src = audiourl;
                        window.audio.onended = () => {
                          console.log("Das Audio ist zu Ende gespielt.");
                          // Hier können Sie weitere Aktionen durchführen, z.B. den Abspielstatus aktualisieren
                          this.isPlaying = false;
                        };
                        window.audio.play();
                        this.isPlaying = true;
                        //var audio = new Audio(audiourl);
                        //audio.play();

                      }).catch(err => {
                        this.showprogress = false;
                        console.log(err);
                      })

                  } else if(cloudprovider == "elevenvoices") {
                      console.log("testelvenvoices");
                      var elevenvoiceid = params.VoiceId;
                      var eleventext = voicetext;



                      if (this.inputs[index].activeTab == 1) {
                        eleventext = document.getElementById("ssmlVoiceEntry"+ index).value;
                      }
                      API.post('vuemixApi', `/texttospeecheleven`, {
                        body: {

                          speechtext: eleventext,
                          name: elevenvoiceid

                        }
                      }).then(result => {
                        //var audiourl = JSON.parse(result);
                        console.log(result.message.Location);
                        console.log(result.message);
                        //this.elevenlastcreatedtext = eleventext;
                        //this.elevenlastcreatedvoice = elevenvoiceid;
                        this.inputs[index].elevenlastcreatedtext = eleventext;
                        this.inputs[index].elevenlastcreatedvoice = elevenvoiceid;


                        var audiourl = result.message.Location;
                        //this.elevenaudiokey = result.message.key;
                        this.inputs[index].elevenaudiokey = result.message.key;
                        window.audio.src = audiourl;
                        window.audio.onended = () => {
                          console.log("Das Audio ist zu Ende gespielt.");
                          // Hier können Sie weitere Aktionen durchführen, z.B. den Abspielstatus aktualisieren
                          this.isPlaying = false;
                        };
                        window.audio.play();
                        this.isPlaying = true;
                        //var audio = new Audio(audiourl);
                        //audio.play();

                      }).catch(err => {
                        this.showprogress = false;
                        console.log(err);
                      })

                }







            },

            //Auswahl des Soundmoods
            changeSound: function () {
                document.getElementById('audiomooddiv').style.display = "block"
                document.getElementById('soundmoodpreview').style.display = "block"

                document.getElementById('sound1').src = 'https://comevismixsrc.s3.eu-central-1.amazonaws.com/' + document.getElementById('soundSelected').value
                console.log("selected sound: " + document.getElementById('sound1').src)
            },
          changeIntroSound: function () {
            document.getElementById('intromoodpreview').style.display = "block"
            document.getElementById('intromooddiv').style.display = "block"

            document.getElementById('previewintrosound').src = 'https://comevismixsrc.s3.eu-central-1.amazonaws.com/' + document.getElementById('introSelected').value
            console.log("selected sound: " + document.getElementById('previewintrosound').src)
          },
          changeOutroSound: function () {
            document.getElementById('outromoodpreview').style.display = "block"
            document.getElementById('outromooddiv').style.display = "block"

            document.getElementById('previewoutrosound').src = 'https://comevismixsrc.s3.eu-central-1.amazonaws.com/' + document.getElementById('outroSelected').value
            console.log("selected sound: " + document.getElementById('previewoutrosound').src)
          },


            //REST Abfrage des Usercounts für den eingeloggten Benutzer
            getUsercount: function (){
                console.log("getUsercount");
                return API.get('vuemixApi', `/users`, {}).then((result) => {
                    console.log(result);
                    this.counter = JSON.parse(result.body).counter;
                    this.elevenvoicesdynamic = JSON.parse(result.body).elevenlabsvoices;
                    //boolean silenceoption
                    this.silenceoption = JSON.parse(result.body).silenceoption;
                    console.log("silenceoption: " + this.silenceoption);

                }).catch(err => {
                    console.log(err);
                })
            },

          // Abfrage der Soundmoods für den Benutzer
            getSoundmoods: function(){


                console.log("getSoundmoods");

                const self = this;
                var prefixu = this.user.username;
                var paramssoundmood = {
                    Bucket: 'comevismixsrc', /* required */

                    Prefix: prefixu + '/',  // Can be your folder name
                    Delimiter: "/",
                    StartAfter: prefixu + '/'
                };
                s3.listObjectsV2(paramssoundmood, function(err, data) {

                    if (err) {
                        console.log("error soundmoods" + err, err.stack);
                    } // an error occurred
                    else {
                        self.soundmoodList = [];
                        console.log("aktuelle silenceoption " + self.silenceoption);
                        if(self.silenceoption == true){
                          var emptysoundmood = { 'Key' : "No Soundmasking", 'value': "no" }
                          self.soundmoodList.push(emptysoundmood);
                        }

                        for (var i = 0; i < data.Contents.length; i++) {
                            var keyvalue = data.Contents[i].Key;    //See above code for the structure of data.Contents
                            var key = "";
                            // var PREFIX = self.prefix;
                            var PREFIX = prefixu + '/';
                                // PREFIX is exactly at the beginning
                                key = keyvalue.slice(PREFIX.length);

                            var newsoundmood = { 'Key' : key, 'value': keyvalue }
                                self.soundmoodList.push(newsoundmood);

                        }

                        console.log("success response" + JSON.stringify(data.Contents));           // successful response
                        // var objtest = {Did: 1, DName: "Department 1"};
                        // self.soundmoodList = data.Contents;

                        // this.soundmoodList.push({Key: 4, DName: "Department 1"});
                        // console.log("success concat" + JSON.stringify(this.soundmoodList));
                        console.log("success concat" + JSON.stringify(data.Contents[1]));
                        // this.soundmoodList = data;
                        this.soundmoodLoaded = true;
                        this.soundmoodLoaded = true;
                    }
                });
            },
          // Abfrager der Intromoods für den Benutzer
          getIntromoods: function() {
            console.log("getIntromoods");

            const self = this;
            var prefixu = this.user.username;
            var folderToSearch = "intro";  // Der Name des Unterordners
            var paramssoundmood = {
              Bucket: 'comevismixsrc', /* required */

              Prefix: prefixu + '/' + folderToSearch + '/',  // Suche im Unterordner "intro"
              Delimiter: "/",
              StartAfter: prefixu + '/' + folderToSearch + '/'
            };
            s3.listObjectsV2(paramssoundmood, function (err, data) {

              if (err) {
                console.log("error intromoods" + err, err.stack);
              } // an error occurred
              else {
                self.intromoodList = [];
                self.intromoodList.push("");
                var PREFIX = prefixu + '/' + folderToSearch + '/';
                for (var i = 0; i < data.Contents.length; i++) {
                  var keyvalue = data.Contents[i].Key;    //See above code for the structure of data.Contents
                  var key = "";
                  // var PREFIX = self.prefix;

                  // PREFIX is exactly at the beginning
                  if (keyvalue.startsWith(PREFIX)) {
                    key = keyvalue.slice(PREFIX.length);
                  }


                  var newintromood = {'Key': key, 'value': keyvalue}
                  self.intromoodList.push(newintromood);

                }
                console.log("intromoodlist currently: " + JSON.stringify(self.intromoodList));


              }
            });
          },
          // Abfrager der Outromoods für den Benutzer
          getOutromoods: function() {
            console.log("getSoundmoods");

            const self = this;
            var prefixu = this.user.username;
            var folderToSearch = "outro";  // Der Name des Unterordners
            var paramssoundmood = {
              Bucket: 'comevismixsrc', /* required */

              Prefix: prefixu + '/' + folderToSearch + '/',  // Suche im Unterordner "intro"
              Delimiter: "/",
              StartAfter: prefixu + '/' + folderToSearch + '/'
            };
            s3.listObjectsV2(paramssoundmood, function (err, data) {

              if (err) {
                console.log("error intromoods" + err, err.stack);
              } // an error occurred
              else {
                self.outromoodList = [];
                self.outromoodList.push("");
                var PREFIX = prefixu + '/' + folderToSearch + '/';
                for (var i = 0; i < data.Contents.length; i++) {
                  var keyvalue = data.Contents[i].Key;    //See above code for the structure of data.Contents
                  var key = "";
                  // var PREFIX = self.prefix;

                  // PREFIX is exactly at the beginning
                  if (keyvalue.startsWith(PREFIX)) {
                    key = keyvalue.slice(PREFIX.length);
                  }


                  var newoutromood = {'Key': key, 'value': keyvalue}
                  self.outromoodList.push(newoutromood);

                }


              }
            });
          },

          // Momentan nicht genutzte Funktion, kann zum Aufrufen aller gemixten Sounds umgebaut werden
            getSounds: function () {
                console.log("getSounds");
                API.get('vuemixApi', `/soundmix`, {}).then((result) => {
                    console.log("result sounds " + result);

                }).catch(err => {
                    console.log("result error sounds " + err);
                })
            },


          // Ruft einen gemixten Sound mit id = lastSoundId auf
            getSound: function (sid, index) {
                // const id = this.lastSoundId;
                const id = sid;
                if (!id) return;
                if (index > 75) {
                  this.showprogress = false;
                  return;
                }
                console.log(`getSound-${id}`);
                API.get('vuemixApi', `/soundmix/${id}`, {}).then((result) => {
                    console.log(result);

                    // this.data[0].id = JSON.parse(result.body).id;

                    if (JSON.parse(result.body).status === "PROCESSING2"){
                      index = index+1;
                        //solange aufruf bis ergebnis verarbeitet, oder neue lastSoundId, alternative wäre counter übergeben bis max 10 aufrufe
                      setTimeout(() => { this.getSound(sid, index) }, 1000);
                      // setTimeout(this.getSound(sid, index), 4000);
                    } else if (JSON.parse(result.body).status === "ERROR"){
                      this.generation_error_toast();

                    } else {
                        this.showprogress = false;
                        var player = "";
                        // var resulturl = JSON.parse(result.body).url;

                        var splitfileformat = JSON.parse(result.body).url.split(".");
                        if (splitfileformat[splitfileformat.length-1] == "wma") {

                          player = "<a href='"+ JSON.parse(result.body).url + "'>Download</a>";
                        } else {
                          player = "<audio controls><source src='" + JSON.parse(result.body).url + "' type='audio/mpeg'></audio> <a href='"+ JSON.parse(result.body).url + "' style='vertical-align: 100%;\n" +
                              "text-align: left;'>Download</a>";


                        }

                        var displayvoice =  JSON.parse(result.body).voicearray[0].voice;
                        if (JSON.parse(result.body).voicearray[0].cloudprovider == "elevenvoices") {
                          displayvoice = this.getDisplayElevenVoice(displayvoice);
                        }
                        this.finaldata.unshift({'id': JSON.parse(result.body).id,'text': JSON.parse(result.body).voicearray[0].textvoice,'soundmood': JSON.parse(result.body).soundfile, 'url': player, 'voice': displayvoice, 'ratio': JSON.parse(result.body).ratio, 'threshold': JSON.parse(result.body).threshold, 'fadein': JSON.parse(result.body).fadein,'fadeout': JSON.parse(result.body).fadeout,});
                        this.getUsercount();
                    }


                    // document.getElementById("results").innerText = JSON.parse(result.body);
                }).catch(err => {
                    this.showprogress = false;
                    console.log(err);

                })
            },

          // addTodo Funktion = eigentlich generateSoundmix Funktion, sendet einen REST-Post Call mit den Voice und Mix Parametern als Auftrag ans Backend, dieses mischt dann anhand der Parameter dieß mp3 Datei
            addTodo: function () {
              this.showprogress = true;
              if (this.isSwitchedCustom == "Einzel Generierung") {
                //multiple files generation, one file per voice
                this.inputs.forEach((value, index) => {
                  var tmparray1 = [];
                  var tmpvoice = value.voice;

                  var split = tmpvoice.split(",");
                  var tmpvoicechoise = split[0];
                  //Standard oder Neural Engine
                  var tmpengine = split[1];
                  var tmpcloudprovider = split[2];

                  var tmptexttype = "text";
                  var tmptextvoice = document.getElementById("textVoiceEntry" + index).value;

                  if (value.activeTab == 1) {
                    tmptexttype = "ssml";
                    tmptextvoice = document.getElementById("ssmlVoiceEntry" + index).value;
                  }
                  if(value.elevenlastcreatedvoice == tmpvoicechoise && value.elevenlastcreatedtext == tmptextvoice) {
                    tmpengine = "elevenpregenerated";
                    // tmptexttype = this.elevenaudiokey;
                    tmptexttype = value.elevenaudiokey;
                  }

                  tmparray1.push({voice: tmpvoicechoise, engine: tmpengine, texttype: tmptexttype, textvoice: tmptextvoice, cloudprovider: tmpcloudprovider });

                  console.log(value);
                  console.log(index);

                  var introElement = document.getElementById('introSelected')
                  var soundmoodElement = document.getElementById('soundSelected');
                  var outroElement = document.getElementById('outroSelected');

                  var intro = introElement ? introElement.value : null;
                  var soundmood = soundmoodElement ? soundmoodElement.value : null;
                  var outro = outroElement ? outroElement.value : null;

                  /*
                  if (soundmood === "") {
                 //   soundmood = "creator_default.mp3";
                  } */


                  API.post('vuemixApi', `/soundmix`, {
                    body: {


                      voicearray: tmparray1,

                      soundfile: soundmood,
                      introfile: intro,
                      outrofile: outro,
                      threshold: this.thresholdvalue,
                      ratio: this.ratiovalue,
                      fadein: this.fadeinvalue,
                      fadeout: this.fadeoutvalue,
                      status: 'PROCESSING',
                      filetype: this.radio,
                      username: this.user.username,
                      volumegain: this.volumegain,

                    }
                  }).then(result => {
                    console.log(result);
                    //Todo: lastSoundId Liste mit den Ids der vorher aufgerufenen PostRequests erstellen, um Reihenfolge zu gewährleisten oder aber sogar PostRequests als Promise All Resolve Sequentiell gestalten
                    //ggf lastSoundId get abfrage anpassen das sie auf liste zugreift
                    this.lastSoundId = JSON.parse(result.body).id;
                    // setTimeout(function(){ this.getSounds(); },3000);
                    this.getSound(JSON.parse(result.body).id, 1);
                    // this.getUsercount();

                  }).catch(err => {
                    console.log("there was an error with connecting to the backend api: ")
                    this.showprogress = false;
                    this.generation_api_error_toast();
                    console.log(err);
                  })


                });




              } else {
                //one file generation, all voices get merged into one file

                  var tmparray = [];
                  this.inputs.forEach((value, index) => {
                    var tmpvoice = value.voice;

                    var split = tmpvoice.split(",");
                    var tmpvoicechoise = split[0];
                    var tmpengine = split[1];
                    var tmpcloudprovider = split[2];
                    var tmptexttype = "text";
                    var tmptextvoice = document.getElementById("textVoiceEntry" + index).value;
                    if (value.activeTab == 1) {
                      tmptexttype = "ssml";
                      tmptextvoice = document.getElementById("ssmlVoiceEntry" + index).value;
                    }
                    //if(this.elevenlastcreatedvoice == tmpvoicechoise && this.elevenlastcreatedtext == tmptextvoice) {
                    if(value.elevenlastcreatedvoice == tmpvoicechoise && value.elevenlastcreatedtext == tmptextvoice) {
                      tmpengine = "elevenpregenerated";
                      // tmptexttype = this.elevenaudiokey;
                      tmptexttype = value.elevenaudiokey;
                    }
                    tmparray.push({voice: tmpvoicechoise, engine: tmpengine, texttype: tmptexttype, textvoice: tmptextvoice, cloudprovider: tmpcloudprovider});

                    console.log(value);
                    console.log(index);
                  });

                  console.log(`addMixTask`);

                  // var valuetmp = this.selectedvoice;
                  // var split = valuetmp.split(",");
                  // var voice = split[0];
                  // var engine = split[1];
                  // var voice = document.getElementById("textVoiceEntry").value;
                  // var engine = document.getElementById("textVoiceEntry").value;

                  var introElement = document.getElementById('introSelected')
                  var soundmoodElement = document.getElementById('soundSelected');
                  var outroElement = document.getElementById('outroSelected');

                var intro = introElement ? introElement.value : null;
                var soundmood = soundmoodElement ? soundmoodElement.value : null;
                var outro = outroElement ? outroElement.value : null;

                  //if (soundmood === "") {
                  //  soundmood = "creator_default.mp3";
                  //}


                  API.post('vuemixApi', `/soundmix`, {
                    body: {


                      voicearray: tmparray,
                      soundfile: soundmood,
                      introfile: intro,
                      outrofile: outro,
                      threshold: this.thresholdvalue,
                      ratio: this.ratiovalue,
                      fadein: this.fadeinvalue,
                      fadeout: this.fadeoutvalue,
                      status: 'PROCESSING',
                      filetype: this.radio,
                      username: this.user.username,
                      volumegain: this.volumegain,

                    }
                  }).then(result => {
                    console.log(result);
                    this.lastSoundId = JSON.parse(result.body).id;
                    // setTimeout(function(){ this.getSounds(); },3000);
                    this.getSound(JSON.parse(result.body).id, 1);
                    // this.getUsercount();

                  }).catch(err => {
                    this.showprogress = false;
                    console.log("there was an error with connecting to the backend api: ")
                    this.generation_api_error_toast();
                    console.log(err);
                  })
              }

            },

            updateTodo: function () {
                const id = this.lastTodoId;
                if (!id) return;
                console.log(`updateTodo-${id}`);
                API.put('vuemixApi', `/soundmix`, {
                    body: {
                        id: id,
                        text: "todo2",
                        complete: true
                    }
                }).then(result => {
                    console.log(result);
                }).catch(err => {
                    console.log(err);
                })
            },
            deleteTodo: function () {
                const id = this.lastTodoId;
                if (!id) return;
                console.log(`deleteTodo-${id}`);
                API.del('vuemixApi', `/soundmix/${id}`, {}).then(result => {
                    console.log(result);
                    this.lastTodoId = "";
                }).catch(err => {
                    console.log(err);
                })
            },
        }
    }
</script>

<style lang="scss">
    @import './assets/styles/main.scss';
</style>

<style>

    /*https://github.com/aws-amplify/amplify-js/issues/2471*/
    /*https://docs.amplify.aws/ui/customization/theming/q/framework/react*/
    /*https://docs.amplify.aws/ui/auth/authenticator/q/framework/vue#hiding-form-fields*/
    :root {

        --amplify-primary-color: #131C21;
        --amplify-primary-tint: #1b2a2f;
        --amplify-primary-shade: #0e1419;
    }

    amplify-sign-out {
        --amplify-primary-color: rgba(0, 0, 0, 0.4);

    }
    /*#app {*/
    /*    font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*    -webkit-font-smoothing: antialiased;*/
    /*    -moz-osx-font-smoothing: grayscale;*/
    /*    text-align: center;*/
    /*    color: #2c3e50;*/
    /*}*/

    /*:root {*/
    /*    --amazonOrange: #FF9900;*/
    /*    --lightAmazonOrange: #FFAC31;*/
    /*    --darkAmazonOrange: #E88B01;*/
    /*    --squidInk: #232F3E;*/
    /*    --lightSquidInk: #31465F;*/
    /*    --deepSquidInk: #152939;*/
    /*    --grey: #828282;*/
    /*    --lightGrey: #C4C4C4;*/
    /*    --silver: #E1E4EA;*/
    /*    --darkBlue: #31465F;*/
    /*    --red: #DD3F5B;*/
    /*    --white: #FFFFFF;*/
    /*    --light-blue: #00a1c9;*/
    /*    --button-color: var(--white);*/
    /*    --button-background-color: var(--amazonOrange);*/
    /*    --button-click: var(--darkAmazonOrange);*/
    /*    --link-color: var(--amazonOrange);*/
    /*    --form-color: var(--white);*/
    /*    --input-color: var(--deepSquidInk);*/
    /*    --input-background-color: var(--white);*/
    /*    --font-family: "Amazon Ember", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";*/
    /*    --body-background: #F8F4F4;*/
    /*    --component-width-desktop: 460px;*/
    /*    --component-width-mobile: 100%;*/
    /*    --color-primary: #FF9900;*/
    /*    --color-primary-accent: #232F3E;*/
    /*    --color-primary-highlight: #FFC46D;*/
    /*    --color-background: #232F3E;*/
    /*    --color-secondary: #152939;*/
    /*    --color-secondary-accent: #31465F;*/
    /*    --color-danger: #DD3F5B;*/
    /*    --color-error: #D0021B;*/
    /*    --color-accent-brown: #828282;*/
    /*    --color-accent-blue: #E1E4EA;*/
    /*    --gradient-blaze: linear-gradient(270deg, #FFC300 0%, #FF9000 100%);*/
    /*    --color-blue: #007EB9;*/
    /*    --color-purple: #527FFF;*/
    /*    --color-gray: #828282;*/
    /*    --color-white: #FFFFFF;*/
    /*    --input-border: 1px solid #C4C4C4;*/
    /*    --input-padding: 0.5em 0.5em 0.3em 1em;*/
    /*    --box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);*/
    /*    --button-height: 42px;*/
    /*    --interactions-conversation-height: 250px;*/
    /*    --ion-color-primary: #FF9900;*/
    /*    --ion-color-primary-rgb: 255, 153, 0;*/
    /*    --ion-color-primary-contrast: #fff;*/
    /*    --ion-color-primary-contrast-rgb: 255, 255, 255;*/
    /*    --ion-color-primary-shade: #232F3E;*/
    /*    --ion-color-primary-tint: #FFC46D;*/
    /*}*/

    /*html,*/
    /*body {*/
    /*    font-family: "Amazon Ember", "Helvetica", "sans-serif";*/
    /*    margin: 0;*/
    /*}*/

    /*a {*/
    /*    color: #0811ff;*/
    /*}*/

    /*h1 {*/
    /*    font-weight: 300;*/
    /*}*/

    /*.app {*/
    /*    width: 100%;*/
    /*}*/

    /*.app-header {*/
    /*    color: white;*/
    /*    text-align: center;*/
    /*    background: linear-gradient(30deg, #0b1eff 55%, #2522ff);*/
    /*    width: 100%;*/
    /*    margin: 0 0 1em 0;*/
    /*    padding: 3em 0 3em 0;*/
    /*    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);*/
    /*}*/

    /*.app-body {*/
    /*    width: 60%;*/
    /*    margin: 0 auto;*/
    /*    text-align: center;*/
    /*    min-height: 500px;*/
    /*}*/

    /*.form-body {*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    display: -webkit-flex;*/
    /*    -webkit-justify-content: center;*/
    /*    -webkit-align-items: center;*/
    /*    flex-direction: row;*/
    /*    flex-wrap: wrap;*/
    /*}*/

    /*.form-body button {*/
    /*    background-color: #0007ff;*/
    /*    font-size: 14px;*/
    /*    color: white;*/
    /*    text-transform: uppercase;*/
    /*    padding: 1em;*/
    /*    border: none;*/
    /*    cursor: pointer;*/
    /*    margin: 10px;*/
    /*}*/

    /*button:hover {*/
    /*    opacity: 0.8;*/
    /*}*/

    /*!* remove blue highlight *!*/
    /*textarea:hover,*/
    /*input:hover:not([type="checkbox"]),*/
    /*textarea:active,*/
    /*input:active:not([type="checkbox"]),*/
    /*textarea:focus,*/
    /*input:focus:not([type="checkbox"]),*/
    /*button:focus,*/
    /*button:active,*/
    /*button:hover,*/
    /*label:focus,*/
    /*.btn:active,*/
    /*.btn.active,*/
    /*select {*/
    /*    outline: 0px !important;*/
    /*    -webkit-appearance: none;*/
    /*    box-shadow: none !important;*/
    /*}*/


    .welcome {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .signout {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }



    .welcome h1 {
        margin-right: 40px;
    }

    amplify-authenticator {
      --container-height: 100%;
    }



    /*button:disabled,*/
    /*button[disabled] {*/
    /*    border: 1px solid #999999;*/
    /*    background-color: #cccccc;*/
    /*    color: #666666;*/
    /*}*/
    .audio-player {
      --player-button-width: 3em;
      --sound-button-width: 2em;
      --space: .5em;
      width: 15rem;
      height: 15rem;
    }





    .controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-top: 10px;
    }

    .player-button {
      background-color: transparent;
      border: 0;
      width: var(--player-button-width);
      height: var(--player-button-width);
      cursor: pointer;
      padding: 0;
    }

    .timeline {
      -webkit-appearance: none;
      width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
      height: .5em;
      background-color: #e5e5e5;
      border-radius: 5px;
      background-size: 0% 100%;
      background-image: linear-gradient(#DE5E97, #DE5E97);
      background-repeat: no-repeat;
      margin-right: var(--space);
    }

    .timeline::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all .1s;
      background-color: #a94672;
    }

    .timeline::-moz-range-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all .1s;
      background-color: #a94672;
    }

    .timeline::-ms-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all .1s;
      background-color: #a94672;
    }

    .timeline::-webkit-slider-thumb:hover {
      background-color: #943f65;
    }

    .timeline:hover::-webkit-slider-thumb {
      opacity: 1;
    }

    .timeline::-moz-range-thumb:hover {
      background-color: #943f65;
    }

    .timeline:hover::-moz-range-thumb {
      opacity: 1;
    }

    .timeline::-ms-thumb:hover {
      background-color: #943f65;
    }

    .timeline:hover::-ms-thumb {
      opacity: 1;
    }

    .timeline::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    .timeline::-moz-range-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    .timeline::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }


</style>