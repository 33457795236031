
import Vue from 'vue'
import App from './App.vue'
import Buefy from "buefy";
import 'buefy/dist/buefy.css'

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import VueEllipseProgress from 'vue-ellipse-progress';



Amplify.configure(aws_exports);



Vue.use(Buefy);
Vue.use(VueEllipseProgress);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')